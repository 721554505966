import { $api } from "@/services/constant";
import { HOST_API_V1 } from "@/services/env-init";

const BASE_URL_V1_ADMIN = HOST_API_V1 + "admin";
const BASE_TRANSAKSI_URL = HOST_API_V1 + "admin/transaksi/";

export const forgotPassword = async (payload: any) => {
  const res = await $api().put(BASE_URL_V1_ADMIN + "/forgot-password", payload);
  return res.data;
};

export const getTransaksiById = async (invoiceNumber: string) => {
  const res = await $api().get(BASE_TRANSAKSI_URL + `income/` + invoiceNumber);
  return res.data;
};

export const updateIncome = async (invoiceNumber: string, payload: any) => {
  const res = await $api().put(
    BASE_TRANSAKSI_URL + `income/` + invoiceNumber,
    payload
  );
  return res.data;
};
